.button-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button-image div {
    margin: auto;
  }
  
  .button-image div svg {
    display: block;
    justify-content: center;
  }
  
  .ql-toolbar.ql-snow {
    border: 1px solid #dfe3e8;
    border-radius: 8px;
  }
  
  div[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
  
  span[disabled] {
    pointer-events: none;
    opacity: 0.2;
  }
  
  button[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
  
  .ql-container.ql-snow {
    border: none;
  }
  
  .ql-snow.ql-toolbar button {
    width: 30px;
    height: 30px;
    margin-left: 3px;
    margin-right: 3px;
  }
  
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    padding: 0;
  }
  
  .ql-snow .ql-toolbar button {
    height: 32px;
    padding: 7px 7px;
    width: 32px;
    margin: 6px 0;
  }
  
  .ql-snow .ql-toolbar button:hover,
  .ql-snow .ql-toolbar button.ql-active {
    background-color: #f4f6f8;
    border-radius: 26px;
  }
  
  .ql-snow.ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:hover .ql-stroke {
    background-color: #eaedf0;
    border-radius: 20px;
    color: #161e26;
  }
  
  .ql-picker-label svg {
    display: none;
  }
  
  .ql-picker-label {
    display: inline-block;
    background-image: url(resources/images/dropdownArrow.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    font-family: 'Work Sans Regular', sans-serif;
    font-size: 12px;
  }
  
  .ql-picker-label::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #161e26;
  }
  
  /* Different icons for different values */
  .ql-picker-label[data-value='left']::before {
    content: '\f036'; /* Unicode code for the specific Font Awesome icon (left alignment) */
  }
  
  .ql-picker-label[data-value='right']::before {
    content: '\f038';
  }
  
  .ql-picker-label[data-value='center']::before {
    content: '\f037';
  }
  
  .ql-picker-label[data-value='justify']::before {
    content: '\f039';
  }
  
  .ql-picker-label[data-value='left']::before,
  .ql-picker-label[data-value='right']::before,
  .ql-picker-label[data-value='center']::before,
  .ql-picker-label[data-value='justify']::before {
    font-family: 'Font Awesome 6 Free', sans-serif; /* Font family used for Font Awesome icons */
    font-weight: 900; /* Sets the font weight to bold for the Font Awesome icon */
    left: 4px;
  }

  .ql-picker-label[data-value='left']::after,
  .ql-picker-label[data-value='right']::after,
  .ql-picker-label[data-value='center']::after,
  .ql-picker-label[data-value='justify']::after {
    font-weight: 900;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: x-small;
    left: 17px;
    color: #161E26;
    height: 20px
  }

  .ql-picker-label[data-value='left'],
  .ql-picker-label[data-value='right'],
  .ql-picker-label[data-value='center'],
  .ql-picker-label[data-value='justify'] {
    background-position: right 2px center;
  }
  
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke {
    background-color: #eaedf0;
    border-radius: 20px;
    color: #161e26;
  }
  
  .ql-snow.ql-toolbar .ql-picker-label:hover {
    color: #161e26;
    background-color: #eaedf0;
    border-radius: 20px;
  }
  
  .ql-snow .ql-picker {
    width: 110px;
    border-radius: 5px;
    background-color: #ffffff !important;
    color: #161e26;
    height: 30px;
    font-size: 1rem;
    align-items: center !important;
    font-weight: 400;
  }
  
  .ql-snow.ql-toolbar .ql-picker-label.ql-active {
    color: #637381;
    background-color: #dfe3e8;
    border-radius: 20px;
  }
  
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
    stroke: #637381;
  }
  
  .alignment {
    width: 30px !important;
    text-align: -webkit-center;
  }
  
  .ql-snow .ql-picker.ql-heading .ql-picker-item[data-value]::before {
    font-size: 12px;
  }

  .ql-toolbar.ql-snow .ql-picker-label[data-value='']::after,
  .ql-toolbar.ql-snow .ql-picker-label[data-value='0']::after,
  .ql-toolbar.ql-snow .ql-picker-label[data-value='1']::after,
  .ql-toolbar.ql-snow .ql-picker-label[data-value='2']::after {
    font-weight: 900;
    position: absolute;
    top: 15%;
    font-size: x-small;
    right: 10px;
    color: #161E26;
  }
  
  .ql-snow .ql-picker.ql-heading .ql-picker-item[data-value]:hover {
    background-color: #eff1f4;
    color: #000000;
  }
  
  .ql-snow .ql-picker.ql-align .ql-picker-item[data-value]:hover {
    background-color: #eff1f4;
    color: #000000;
  }
  
  .ql-snow .ql-toolbar button:hover div,
  .ql-snow .ql-toolbar button.ql-active div {
    filter: brightness(3);
  }
  
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent;
  }
  
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #ffffff;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background-color: #ffffff;
    font-family: 'Work Sans Medium', sans-serif;
  }
  
  .ql-snow .ql-picker.ql-expanded .ql-picker-options:hover {
    background-color: 'white';
  }
  
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
    stroke: #637381;
  }
  
  .ql-snow .ql-picker-options {
    padding: 0;
    border-radius: 10px;
    margin-top: 12px !important;
    overflow: hidden auto;
  }
  
  .ql-snow.ql-toolbar button svg,
  .ql-snow .ql-toolbar button svg {
    height: 1em;
  }
  
  .ql-snow .ql-picker-label {
    padding-left: 10px;
  }
  
  .ql-snow.ql-toolbar .ql-picker-item:hover {
    color: #637381;
  }
  
  .ql-snow .ql-picker-options .ql-picker-item {
    background-color: white;
    border-bottom: 0.5px solid;
    border-bottom-color: #f4f6f8;
    padding: 6px 0px 6px 16px;
    background-position-x: center !important;
    height: 32px;
  }
  
  .ql-snow .ql-icon-picker .ql-picker-item {
    width: 32px;
    padding: 0 !important;
  }
  
  .ql-snow .ql-picker-label::before {
    display: inline-block;
    line-height: 32px;
  }
  
  .ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #161e26;
  }
  
  .ql-snow .ql-editor .ql-heading-0,
  .ql-snow .ql-editor .ql-heading-0:hover {
    color: #555555;
    font-family: 'Arial', sans-serif;
    font-size: 1.5rem;
    margin-block-end: 1rem;
    margin-right: 5rem;
    max-width: 68rem;
    display: block;
    margin-block-start: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  
  .ql-toolbar.ql-snow .ql-formats {
    margin: 4px 3px 4px 0;
  }
  
  .ql-snow .ql-editor .ql-heading-1,
  .ql-snow .ql-editor .ql-heading-1:hover {
    color: #555555;
    font-family: 'Arial', sans-serif;
    font-size: 1.25rem;
    margin-block-end: 1rem;
    margin-right: 5rem;
    max-width: 68rem;
    display: block;
    margin-block-start: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  
  .ql-snow .ql-editor .ql-heading-2,
  .ql-snow .ql-editor .ql-heading-2:hover {
    color: #555555;
    font-family: 'Arial', sans-serif;
    font-size: 1.125rem;
    margin-block-end: 1rem;
    margin-right: 5rem;
    max-width: 68rem;
    display: block;
    margin-block-start: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  
  .ql-snow a {
    cursor: pointer;
  }
  
  .ql-editor {
    color: #333333;
    font-family: 'Arial', sans-serif;
    font-size: 15px;
    padding-top: 33px;
    line-height: 25px;
    padding-left: 0;
  }
  
  .ql-clipboard {
    position: fixed;
    display: none;
  }
  
  .move-toolbar-right {
    transform: translateX(37%);
  }
  
  .highlight {
    background-color: yellow !important;
  }
  
  .insertedText {
    background-color: #dfe3e8;
    border-bottom: 3px solid #919eab;
  }
  
  .active-highlight {
    background-color: orange !important;
  }
  
  .active-highlight-edit {
    background-color: orange !important;
    border-bottom: 3px solid #919eab;
  }
  
  .comment {
    background-color: #dfe3e8;
  }

  .activeComment {
    background-color: #b2c4ff;
  }
  
  .paragraph_suggestion {
    background-color: #efe9ff;
  }

  .excluded_text {
    background-color: #eaedf0;
  }

  .complex_sentence_simplification {
    text-decoration-thickness: 2px;
    text-decoration-line: underline;
    text-decoration-color: #fd6f6f;
    cursor: pointer;
  }
  
  .complex_sentence_simplification_active {
    background-color: #ffe1e1;
  }

  .complex_concept_simplification {
    text-decoration-thickness: 2px;
    text-decoration-line: underline;
    text-decoration-color: #ff8900;
    cursor: pointer;
  }
  
  .complex_concept_simplification_active {
    background-color: #ffddc5;
    cursor: pointer;
  }
  
  .complex_word_simplification {
    text-decoration-thickness: 2px;
    text-decoration-line: underline;
    text-decoration-color: #ffc914;
    cursor: pointer;
  }
  
  .complex_word_simplification_active {
    background-color: #fff0c2;
    cursor: pointer;
  }

  .long_sentence_simplification {
    text-decoration-thickness: 2px;
    text-decoration-line: underline;
    text-decoration-color: #018ac1;
    cursor: pointer;
  }
  
  .long_sentence_simplification_active {
    background-color: #b8ebff;
    cursor: pointer;
  }
  
  .writing_style_simplification {
    text-decoration-thickness: 2px;
    text-decoration-line: underline;
    text-decoration-color: #885be3;
    cursor: pointer;
  }
  
  .writing_style_simplification_active {
    background-color: #dbcdf7;
    cursor: pointer;
  }
  
  list-item {
    display: block;
    margin-top: 2rem;
    margin-left: 1rem;
  }
  
  list-item.bold::before {
    font-weight: bold;
  }
  
  list-item.italic::before {
    font-style: italic;
  }
  
  list-item.decimal::before {
    content: counter(ol0) '.' !important;
    width: 1em !important;
    padding-right: 1rem;
  }
  
  list-item.decimal.ql-indent-1:before {
    content: counter(ol0) '.' counter(ol1) '.' !important;
    width: 2em !important;
    padding-right: 1rem;
  }
  
  list-item.decimal.ql-indent-2:before {
    content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' !important;
    width: 3em !important;
  }
  
  list-item.decimal.ql-indent-3:before {
    content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' counter(ol3) '.' !important;
    width: 4em !important;
  }
  
  list-item.decimal.ql-indent-4:before {
    content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' counter(ol3) '.' counter(ol4) '.' !important;
    width: 5em !important;
  }
  
  list-item.decimal.ql-indent-5:before {
    content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' counter(ol3) '.' counter(ol4) '.'
      counter(ol5) '.' !important;
    width: 6em !important;
  }
  
  list-item.decimal.ql-indent-6:before {
    content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' counter(ol3) '.' counter(ol4) '.'
      counter(ol5) '.' counter(ol6) '.' !important;
    width: 7em !important;
  }
  
  list-item.decimal.ql-indent-7:before {
    content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' counter(ol3) '.' counter(ol4) '.'
      counter(ol5) '.' counter(ol6) '.' counter(ol7) '.' !important;
    width: 8em !important;
  }
  
  list-item.decimal.ql-indent-8:before {
    content: counter(ol0) '.' counter(ol1) '.' counter(ol2) '.' counter(ol3) '.' counter(ol4) '.'
      counter(ol5) '.' counter(ol6) '.' counter(ol7) '.' counter(ol8) '.' !important;
    width: 9em !important;
  }
  
  list-item.bullet:before,
  list-item.bullet.ql-indent-3:before,
  list-item.bullet.ql-indent-6:before {
    content: '● ' !important;
  }
  
  list-item.bullet.ql-indent-1:before,
  list-item.bullet.ql-indent-4:before,
  list-item.bullet.ql-indent-7:before {
    content: '○ ' !important;
  }
  
  list-item.bullet.ql-indent-2:before,
  list-item.bullet.ql-indent-5:before,
  list-item.bullet.ql-indent-8:before {
    content: '◾ ' !important;
  }
  
  .ql-snow .ql-picker.ql-heading .ql-picker-label[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-heading .ql-picker-item[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {
    content: attr(data-label);
  }
  
  .ql-editor table,
  .ql-editor th,
  .ql-editor td {
    border: 0.5px solid black;
    border-collapse: collapse;
  }
  
  .ql-editor table {
    width: 100%;
    margin-top: 2rem;
    table-layout: fixed;
  }
  .ql-editor td > * {
    margin: 1rem !important;
  }
  
  .ql-editor > * {
    cursor: inherit !important;
  }
  
  input:focus {
    outline: none;
  }
  
  .search-icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .ql-editor.ql-blank::before {
    left: 0;
  }

  .ql-picker-item[data-value='left']::before {
    content: '\f036';
    font-family: 'Font Awesome 6 Free', sans-serif;
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    color: #161e26;
    width: 13px;
    height: 14px;
    position: absolute;
    left: 30%;
    top: 7%;
  }
  
  .ql-picker-item[data-value='center']::before {
    content: '\f037';
    font-family: 'Font Awesome 6 Free', sans-serif;
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    color: #161e26;
    width: 13px;
    height: 14px;
    position: absolute;
    left: 30%;
    top: 31%;
  }
  
  .ql-picker-item[data-value='right']::before {
    content: '\f038';
    font-family: 'Font Awesome 6 Free', sans-serif;
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    color: #161e26;
    width: 13px;
    height: 14px;
    position: absolute;
    left: 30%;
    top: 55%;
  }
  
  .ql-picker-item[data-value='justify']::before {
    content: '\f039';
    font-family: 'Font Awesome 6 Free', sans-serif;
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    color: #161e26;
    width: 13px;
    height: 14px;
    position: absolute;
    left: 30%;
    top: 79%;
  }
  
  .search-container {
    position: relative;
    display: inline-block;
  }
  
  .search {
    display: flex !important;
    margin-left: 3px;
  }
  
  .search-input {
    position: absolute;
    top: 160%;
    left: 10%;
    z-index: 1000;
    height: 35px !important;
  }
  